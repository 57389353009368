import React, { useState } from "react";

// import ReactGA from "react-ga";

import { Link } from 'react-router-dom'

import {
    Padder,
    Button,
    Container,
    Box,
    Navbar,
    Banner,
    MessageBox
} from 'vandiermen-design-system'

import useWindowDimensions from '../components/UseWindowDimensions'

import preview from '../assets/preview.svg'
import afm from '../assets/afm.jpg'
import gradient1 from '../assets/gradient-1.jpg'
import gradient2 from '../assets/gradient-2.jpg'
import gradient3 from '../assets/gradient-3.jpg'

import '../styles/footer.css'

export const Landing = ({ }) => {

    // ReactGA.pageview(window.location.pathname)

    const { width } = useWindowDimensions();

    return (
        <div>
            <Container width={1100}>
            {/* <MessageBox type="info" message="Currently no management fees charged!" /> */}

                {width > 700
                    ?
                    <Padder top='6em' bottom='6em'>
                        <div style={{ display: "flex", alignItems: "normal", justifyContent: 'space-between' }}>
                            <Padder top="5em">
                                <h1 style={{ marginBottom: '1em' }} >A better way to <br></br> invest in cryptocurrency</h1>
                                <Link to="/signup">
                                    <Button style={{ zoom: 1.3 }} intent="primary">Sign up</Button>
                                </Link>
                            </Padder>
                            <Padder top='1em'>
                                <img src={preview} style={{ minWidth: 200 }} />
                            </Padder>

                        </div>
                    </Padder>
                    :
                    <Padder top="5em">
                        <Padder bottom="2em">
                            <h1 style={{ marginBottom: '1em' }} >A better way to <br></br> invest in cryptocurrency</h1>
                            <Link style={{ width: '100%' }} to="/signup">
                                <Button style={{ zoom: 1.3 }} intent="primary">Sign up</Button>
                            </Link>
                        </Padder>
                        <img src={preview} style={{ maxWidth: '100%' }} />
                    </Padder>
                }
            </Container>


            <Container width={900}>
                <Padder top="6em" bottom="6em">
                    <h2>Service</h2>
                    <p>We provide a better way to invest in cryptocurrency. With trading strategies that aim to outperform the market. With the use of AI and diversification into multiple crypto assets to get broad market exposure and minimalise risk. Our service is for people that want to invest in crypto without active management of a custom portfolio.</p>
                </Padder>
                <Padder top="6em" bottom="6em">
                    <Padder bottom="2em">
                        <h2>Funds</h2>
                    </Padder>
                    <Padder bottom="3em">
                        <Banner height={400} source={gradient1} direction="center">
                            <Padder top="5em" bottom="1em" left="4em" right="2em">
                                <h1 style={{ color: '#fff' }}>The genesis fund</h1>
                                <p style={{ color: '#fff', maxWidth: 450 }}>The liquid token fund. This fund has a diversified portfolio of crypto. The fund uses machine learning models to entry and exit positions. Designed to get the most out of a bull market and protect funds in a bear market. This is the default portfolio you invest in when making a deposit.</p>
                                {/* <Button size="small" intent="minimal" >More</Button> */}
                            </Padder>
                        </Banner>
                    </Padder>
                    <Padder bottom="3em">
                        <Banner height={400} source={gradient2} direction="center">
                            <Padder top="5em" bottom="1em" left="4em" right="2em">
                                <h1 style={{ color: '#fff' }}>The venture fund</h1>
                                <p style={{ color: '#fff', maxWidth: 450 }}>The venture capital fund deploying capital in promising projects. Contact us for more info.</p>
                                {/* <Button size="small" intent="minimal" >More</Button> */}
                            </Padder>
                        </Banner>
                    </Padder>
                </Padder>

                {width > 700 ?
                    <Padder top='6em' bottom='6em'>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                            <div style={{ maxWidth: 400 }}>
                                <h2>Transparent <br></br> Fees</h2>
                                <p>We got transparent fees based on performance and stability. Aligned incentives.</p>
                            </div>
                            <Box width={350}>
                                <h5 style={{ textAlign: 'center' }}>Management</h5>
                                <h5 style={{ color: '#C4C4C4', textAlign: 'center' }}>3%*</h5>
                                <h5 style={{ textAlign: 'center' }}>Performance</h5>
                                <h5 style={{ color: '#C4C4C4', textAlign: 'center' }}>25%**</h5>
                            </Box>
                        </div>
                    </Padder>
                    :
                    <Padder bottom='3em'>
                        <Padder bottom="2em">
                            <h2>Transparent <br></br> Fees</h2>
                            <p>We got transparent fees based on performance and stability. Aligned incentives.</p>
                        </Padder>
                        <Box width={350}>
                            <h5 style={{ textAlign: 'center' }}>Management</h5>
                            <h5 style={{ color: '#C4C4C4', textAlign: 'center' }}>3%*</h5>
                            <h5 style={{ textAlign: 'center' }}>Performance</h5>
                            <h5 style={{ color: '#C4C4C4', textAlign: 'center' }}>25%**</h5>
                        </Box>
                    </Padder>
                }

            </Container>

            <div className="footer" style={{ backgroundColor: '#DCDCDC' }}>
                <div className="container">
                    <div className="group">
                        <label>Company</label>
                        {/* <Link to="/about">
                            <a>About us</a>
                        </Link> */}
                        {/* <Link to="/contact"> */}
                        <a href="mailto:contact@mrcry.capital">Contact</a>
                        {/* </Link> */}
                    </div>

                    <div className="group">
                        <label>Legal</label>
                        <Link to="/disclosures">
                            <a>Disclosures</a>
                        </Link>
                        <Link to="/privacy">
                            <a>Privacy Policy</a>
                        </Link>
                        <Link to="/terms">
                            <a>Terms and Conditions</a>
                        </Link>
                    </div>

                    <div className="group">
                        <label>More</label>
                        <Link to="/firm/login">
                            <a>Firm login</a>
                        </Link>
                    </div>
                </div>
                <Padder top="2em">
                    <Container width={500}>
                        <Padder top="1em" bottom="1em">
                            <p style={{ color: '#787878', textAlign: 'center' }}>
                                Past performance is no guarantee of future performance.
                            </p>
                        </Padder>
                        <p style={{ color: '#787878', textAlign: 'center' }}>
                            *Percentage of balance, calculated annually. **Percentage of profit. Performance fee only charged when profits are made above the high water mark. Ex. of any trading costs charged by third parties like exchanges.
                        </p>
                        <Padder top="1em" bottom="1em">
                            <p style={{ color: '#787878', textAlign: 'center' }}>Cryptocurrency is highly speculative in nature, involves a high degree of risks, such as volatile market price swings, market manipulation, flash crashes, and cybersecurity risks. Cryptocurrency is not regulated or is lightly regulated in most countries. Cryptocurrency trading can lead to large, immediate and permanent loss of financial value. You should have appropriate knowledge and experience before engaging in cryptocurrency trading.</p>
                        </Padder>
                        <div style={{ textAlign: 'center' }}>
                            <img src={afm} style={{ height: 50 }} />
                        </div>

                        {width > 700 ?
                            < Padder top="1em" bottom="2em">
                                <p style={{ color: '#787878', textAlign: 'center' }}>
                                    © 2023, Mercury Capital by vanDiermen Software B.V.
                                </p>
                            </Padder> :
                            <Padder top="1em" bottom="12em">
                                <p style={{ color: '#787878', textAlign: 'center' }}>
                                    © 2023, Mercury Capital by vanDiermen Software B.V.
                                </p>
                            </Padder>
                        }

                    </Container>
                </Padder>
            </div >
        </div >
    );
}